import bincentive from "@/common/assets/image/clientsNew/bincentive.svg"
import Bullion from "@/common/assets/image/clientsNew/Bullion.svg"
import CoolBit from "@/common/assets/image/clientsNew/CoolBit.svg"
import FANSI from "@/common/assets/image/clientsNew/FANSI.svg"
import HoyaBit from "@/common/assets/image/clientsNew/HoyaBit.svg"
import InVar from "@/common/assets/image/clientsNew/InVar.svg"
import OURSONG from "@/common/assets/image/clientsNew/OURSONG.svg"
import invention from "@/common/assets/image/clientsNew/invention.svg"
import Jcard from "@/common/assets/image/clientsNew/Jcard.svg"
import megaBank from "@/common/assets/image/clientsNew/megaBank.svg"
import Ministry from "@/common/assets/image/clientsNew/Ministry.svg"
import Taiwan from "@/common/assets/image/clientsNew/Taiwan.svg"
import TBank from "@/common/assets/image/clientsNew/TBank.svg"
import YGGJ from "@/common/assets/image/clientsNew/YGGJ.svg"
import Bitcool from "@/common/assets/image/clientsNew/Bitcool.svg"
import Joinbit from "@/common/assets/image/clientsNew/Joinbit.svg"
import BitAsset from "@/common/assets/image/clientsNew/BitAsset.svg"
import BitYacht from "@/common/assets/image/clientsNew/BitYacht.svg"
import GoldBit from "@/common/assets/image/clientsNew/GoldBit.svg"
import WowTech from "@/common/assets/image/clientsNew/WowTech.svg"

interface ClientData {
  image: string
  tag: string[]
  tagD: string
  alt: string
  url: string
}

export const CLIENTS_DATA: ClientData[] = [
  {
    image: YGGJ,
    tag: ["Client"],
    tagD: "",
    alt: "YGGJ",
    url: ""
  },
  {
    image: OURSONG,
    tag: ["Client", "VASP"],
    tagD: "VASP",
    alt: "ourSong",
    url: "https://www.oursong.com/"
  },
  {
    image: HoyaBit,
    tag: ["Client", "VASP"],
    tagD: "VASP",
    alt: "Hoyabit",
    url: "https://hoyabit.com/"
  },
  {
    image: bincentive,
    tag: ["Client", "VASP"],
    tagD: "",
    alt: "Bincentive",
    url: "https://www.bincentive.com/"
  },
  {
    image: megaBank,
    tag: ["Client", "Financial", "Financials"],
    tagD: "clients.tag5",
    alt: "兆豐銀行 Mega Bank",
    url: "https://www.megabank.com.tw/personal"
  },
  {
    image: TBank,
    tag: ["Client", "Investment", "Financials"],
    tagD: "clients.tag4",
    alt: "台新資本管理顧問公司",
    url: "https://www.twincn.com/item.aspx?no=85129870"
  },
  {
    image: CoolBit,
    tag: ["Client", "VASP"],
    tagD: "VASP",
    alt: "coolbitX",
    url: "https://coolbitx.com/"
  },
  {
    image: FANSI,
    tag: ["Client", "VASP"],
    tagD: "VASP",
    alt: "FANSI",
    url: "https://www.fansi.me/"
  },
  {
    image: Jcard,
    tag: ["Client", "VASP"],
    tagD: "VASP",
    alt: "JCARD",
    url: "https://jcard.io/tw"
  },
  {
    image: Ministry,
    tag: ["Client"],
    tagD: "",
    alt: "法務部",
    url: "https://www.moj.gov.tw/"
  },
  {
    image: Bullion,
    tag: ["Client", "Investment", "Financials"],
    tagD: "clients.tag4",
    alt: "寶錸投顧",
    url: "https://www.twincn.com/item.aspx?no=88264527"
  },
  {
    image: invention,
    tag: ["Client", "VASP"],
    tagD: "VASP",
    alt: "絕對創新",
    url: "https://jue-dui.com/"
  },
  {
    image: WowTech,
    tag: ["Client", "VASP"],
    tagD: "VASP",
    alt: "萬事萬物股份有限公司",
    url: "https://www.bc3ts.com/"
  },
  {
    image: Taiwan,
    tag: ["Client", "Payment", "Financials"],
    tagD: "clients.tag3",
    alt: "台灣萬事達金流",
    url: "https://www.gomypay.asia/"
  },
  {
    image: Joinbit,
    tag: ["Client"],
    tagD: "",
    alt: "Joinbit",
    url: ""
  },
  {
    image: Bitcool,
    tag: ["Client"],
    tagD: "",
    alt: "Bitcool",
    url: ""
  },
  {
    image: InVar,
    tag: ["Client", "VASP"],
    tagD: "VASP",
    alt: "invar",
    url: "https://www.invar.finance/"
  },
  {
    image: BitAsset,
    tag: ["Client", "VASP"],
    tagD: "VASP",
    alt: "BitAsset",
    url: ""
  },
  {
    image: BitYacht,
    tag: ["Client", "VASP"],
    tagD: "VASP",
    alt: "BitYacht",
    url: ""
  },
  {
    image: GoldBit,
    tag: ["Client", "VASP"],
    tagD: "VASP",
    alt: "GoldBit",
    url: ""
  }
]
