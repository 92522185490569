import React from "react"
import { SolutionNames } from "@/common/data/Products"
import { useTranslation } from "next-i18next"

import DefiImage from "@/common/assets/image/solutions/cards/DeFi.avif"
import GameFiImage from "@/common/assets/image/solutions/cards/GameFi.avif"
import Web3FundManagementImage from "@/common/assets/image/solutions/cards/Web3FundManagementImage.avif"
import Web3CommerceImage from "@/common/assets/image/solutions/cards/Web3CommerceImage.avif"
import Card, { CardData } from "./Card"

export interface SolutionCardProps {
  cardName: any
  type: SolutionNames
}

export default function SolutionCard({ cardName, type }: SolutionCardProps) {
  const { t } = useTranslation(["common"])
  let data: CardData = {}
  switch (type) {
    case SolutionNames.GameFi:
      data = {
        wide: false,
        backgroundType: "dark",
        title: t("SolutionCard.gamefi.title"),
        slogan: t("SolutionCard.gamefi.slogan"),
        description: t("SolutionCard.gamefi.description"),
        button: {
          text: t("SolutionCard.gamefi.button"),
          href: "/solutions/gamefi"
        },
        image: GameFiImage
      }
      break
    case SolutionNames.Defi:
      data = {
        wide: false,
        backgroundType: "light",
        title: t("SolutionCard.defi.title"),
        slogan: t("SolutionCard.defi.slogan"),
        description: t("SolutionCard.defi.description"),
        button: {
          text: t("SolutionCard.defi.button"),
          href: "/solutions/defi"
        },
        image: DefiImage
      }
      break
    case SolutionNames.Web3FundManagement:
      data = {
        wide: false,
        backgroundType: "dark",
        title: t("SolutionCard.Web3FundManagement.title"),
        slogan: t("SolutionCard.Web3FundManagement.slogan"),
        description: t("SolutionCard.Web3FundManagement.description"),
        button: {
          text: t("SolutionCard.Web3FundManagement.button"),
          href: "/solutions/web3-fund-management"
        },
        image: Web3FundManagementImage
      }
      break
    case SolutionNames.Web3Commerce:
      data = {
        wide: false,
        backgroundType: "light",
        title: t("SolutionCard.Web3Commerce.title"),
        slogan: t("SolutionCard.Web3Commerce.slogan"),
        description: t("SolutionCard.Web3Commerce.description"),
        button: {
          disabled: true,
          text: t("SolutionCard.Web3Commerce.button"),
          href: "/"
        },
        image: Web3CommerceImage
      }
      break
    default:
      data = {
        backgroundType: "dark",
        title: t("SolutionCard.defi.title"),
        slogan: t("SolutionCard.defi.slogan"),
        description: t("SolutionCard.defi.description"),
        button: {
          text: t("SolutionCard.defi.button"),
          href: "/solutions/defi"
        }
      }
  }
  return (
    <Card
      cardName={cardName}
      customerImageWrapperStyle={{ borderRadius: 8, overflow: "hidden" }}
      {...data}
    />
  )
}
