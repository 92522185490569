import React from "react"

import { useTranslation } from "next-i18next"
import dynamic from "next/dynamic"
import SectionLine from "@/common/components/_UI/SectionLine"

// Customer testimonials section

import LazyLoadComponent from "@/common/components/LazyLoadComponent"
import Footer from "@/containers/common/FooterNew"
import SolutionCardList from "@/common/components/ProductCard/SolutionCardList"
import { SolutionNames } from "@/common/data/Products"
import styled from "styled-components"
import { CardWrapper } from "@/common/components/ProductCard/Card"

const Description = dynamic(() => import("@/common/components/Description"), {
  ssr: false
})

const ProductCTA = dynamic(() => import("@/common/components/ProductCTA"), {
  ssr: false
})

const Statistic = dynamic(() => import("@/common/components/Statistic"), {
  ssr: false
})

// HomeOtherContent is loaded only after HomeProductBanner finished loading
export default function HomeOtherContent() {
  const { t } = useTranslation(["homepage"])

  return (
    <>
      {/* <div
        style={{ display: "flex", flexDirection: "column", gap: "60px" }}
        id="home-studio"
      >
        <Description
          id="home-studio-desc"
          backgroundType="none"
          items={[
            {
              wide: true,
              titles: [
                { text: t("studio.title.1"), textClass: "primary title" },
                { text: t("studio.title.2"), textType: "h2" },
                { text: t("studio.desc"), textClass: "body disabled" }
              ]
            }
          ]}
        />
        <NextImg
          id="home-studio-img"
          src={StudioImage.src}
          alt="Studio Image"
        />
      </div> */}
      <SectionLine />
      <LazyLoadComponent
        skeletonProps={{
          width: "75%"
        }}
        component={() => import("@/containers/homepage/HomeProducts")}
      />
      <SolutionSection id="Solutions">
        <Description
          backgroundType="none"
          items={[
            {
              wide: true,
              titles: [
                {
                  text: t("solutions.title.1"),
                  textClass: "primary title"
                },
                { text: t("solutions.title.2"), textType: "h3" },
                { text: t("solutions.desc"), textClass: "body disabled" }
              ]
            }
          ]}
        />
        <SolutionCardList
          cards={[SolutionNames.Web3FundManagement, SolutionNames.Web3Commerce]}
        />
        <SolutionCardList cards={[SolutionNames.Defi, SolutionNames.GameFi]} />
      </SolutionSection>
      <SectionLine />
      <Description
        backgroundType="envelope"
        items={[
          {
            wide: true,
            titles: [
              { text: t("TA.title.1"), textClass: "primary title" },
              { text: t("TA.title.2"), textType: "h3" }
            ],
            checkList: [
              {
                title: t("TA.1.name"),
                titleClass: "title contrast",
                list: [
                  { description: t("TA.1.list.1") },
                  { description: t("TA.1.list.2") },
                  { description: t("TA.1.list.3") }
                ]
              },
              {
                title: t("TA.2.name"),
                titleClass: "title contrast",
                list: [
                  { description: t("TA.2.list.1") },
                  { description: t("TA.2.list.2") },
                  { description: t("TA.2.list.3") }
                ]
              },
              {
                title: t("TA.3.name"),
                titleClass: "title contrast",
                list: [
                  { description: t("TA.3.list.1") },
                  { description: t("TA.3.list.2") },
                  { description: t("TA.3.list.3") }
                ]
              }
            ]
          }
        ]}
      />
      <Statistic
        title={t("counter.title")}
        description={t("counter.desc")}
        items={[
          {
            number: 30,
            numberSuffix: t("counter.1.numberSuffix"),
            description: t("counter.1.desc"),
            trend: "smaller"
          },
          {
            number: 8,
            numberSuffix: t("counter.2.numberSuffix"),
            description: t("counter.2.desc"),
            trend: "bigger"
          },
          {
            number: 90,
            numberSuffix: t("counter.3.numberSuffix"),
            description: t("counter.3.desc"),
            trend: "bigger"
          },
          {
            number: 100,
            numberSuffix: t("counter.4.numberSuffix"),
            description: t("counter.4.desc"),
            trend: "bigger"
          }
        ]}
        buttonText={t("counter.button")}
        buttonHref="/contact-us"
      />
      <SectionLine />
      <LazyLoadComponent
        skeletonProps={{
          width: "75%"
        }}
        component={() => import("@/containers/homepage/CustomerTestimonial")}
      />
      <ProductCTA
        title={t("CTA.title")}
        desc={t("CTA.desc")}
        primaryButton={{
          text: t("CTA.button"),
          href: "/contact-us"
        }}
      />
      <Footer />
    </>
  )
}

const SolutionSection = styled.div`
  gap: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2em;

  & ${CardWrapper} {
    height: unset;
    align-self: stretch;
  }
`
