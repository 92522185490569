import React from "react"
import dynamic from "next/dynamic"
import { useInView } from "react-intersection-observer"
import { Skeleton } from "@mui/material"
import styled from "styled-components"

interface LazyLoadComponentProps {
  component: () => Promise<{ default: React.ComponentType<any> }>
  skeletonProps?: {
    width?: string
    height?: string
    bgcolor?: string
    aspectRatio?: string
    minHeight?: string
  }
  props?: any
}

const defaultSkeletonProps = {
  width: "100%",
  height: "100%",
  bgcolor: "grey.900",
  minHeight: "480px"
}

const LazyLoadComponent: React.FC<LazyLoadComponentProps> = ({
  component,
  skeletonProps,
  props
}) => {
  const [ref, inView] = useInView({
    triggerOnce: true, // Load only once when in view
    threshold: 0.1 // Trigger when 10% of the component is in view
  })

  const mergedSkeletonProps = Object.assign(
    {},
    defaultSkeletonProps,
    skeletonProps
  )

  const loadingElement = (
    <CenteredContainer>
      <Skeleton
        animation="wave"
        width={mergedSkeletonProps.width}
        height={mergedSkeletonProps.height}
        sx={{
          bgcolor: mergedSkeletonProps.bgcolor,
          minHeight: mergedSkeletonProps.minHeight
        }}
      />
    </CenteredContainer>
  )
  const DynamicComponent = dynamic(component, {
    loading: () => loadingElement,
    ssr: false
  })

  return (
    <div ref={ref}>
      {inView ? <DynamicComponent {...props} /> : loadingElement}
    </div>
  )
}

export default LazyLoadComponent

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`
