import React, { useEffect, useState } from "react"

import Sticky from "react-stickynode"
import { useTranslation } from "next-i18next"
import SectionLine from "@/common/components/_UI/SectionLine"
import { serverSideTranslations } from "next-i18next/serverSideTranslations"
import MetaData from "@/common/components/MetaData"
import NavbarNew from "@/containers/common/NavbarNew"

import dynamic from "next/dynamic"
import StudioImage from "@/common/assets/image/homepage/Studio.webp"
import NextImg from "@/common/components/NextImg"

import TrustedBy from "@/common/components/TrustedBy"
import { HomeProductBanner } from "@/containers/homepage"
import LazyLoadComponent from "@/common/components/LazyLoadComponent"
import { DarkBGWrapper, LayoutWrapper } from "@/common/components/LayoutStyle"
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/effect-fade"

const Mainpage = () => {
  const [initialContentLoaded, setInitialContentLoaded] = useState(false)

  useEffect(() => {
    setInitialContentLoaded(true)
  }, [])

  const { t } = useTranslation(["homepage"])
  const Description = dynamic(() => import("@/common/components/Description"), {
    ssr: false
  })

  return (
    <>
      <MetaData
        title={t("meta.title")}
        description={t("meta.description")}
        url={t("meta.url")}
        image="https://storage.googleapis.com/kryptogo-official-website-public/og-logo.webp"
        languageAlternates={[
          {
            hrefLang: "en-US",
            href: "https://www.kryptogo.com"
          },
          {
            hrefLang: "zh-TW",
            href: "https://www.kryptogo.com/tw"
          },
          {
            hrefLang: "zh-CN",
            href: "https://www.kryptogo.com/cn"
          },
          {
            hrefLang: "ja-JP",
            href: "https://www.kryptogo.com/ja"
          }
        ]}
      />

      <Sticky top={0} innerZ={1000} activeClass="sticky-active">
        <NavbarNew />
      </Sticky>
      <DarkBGWrapper></DarkBGWrapper>
      <LayoutWrapper>
        <div style={{ display: "flex", width: "100vw" }} id="home-banner">
          <HomeProductBanner />
        </div>
        <TrustedBy title={t("partners.title")} />
        <SectionLine />
        <div
          style={{ display: "flex", flexDirection: "column", gap: "60px" }}
          id="home-studio"
        >
          <Description
            id="home-studio-desc"
            backgroundType="none"
            items={[
              {
                wide: true,
                titles: [
                  { text: t("studio.title.1"), textClass: "primary title" },
                  { text: t("studio.title.2"), textType: "h2" },
                  { text: t("studio.desc"), textClass: "body disabled" }
                ]
              }
            ]}
          />
          <NextImg
            id="home-studio-img"
            src={StudioImage.src}
            alt="Studio Image"
          />
        </div>
        {initialContentLoaded && (
          <LazyLoadComponent
            skeletonProps={{
              width: "75%"
            }}
            component={() => import("@/containers/homepage/HomeOtherContent")}
          />
        )}
      </LayoutWrapper>
    </>
  )
}

export const getStaticProps = async ({ locale }: { locale: string }) => ({
  props: {
    ...(await serverSideTranslations(locale, [
      "common",
      "contactUs",
      "homepage"
    ]))
  }
})

export default Mainpage
