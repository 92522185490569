import React from "react"
import styled from "styled-components"
import { CLIENTS_DATA } from "@/common/data/ClientDataNew"
import NextImg from "@/common/components/NextImg/index"
import { theme } from "@/common/Mixin"

interface TrustedByProps {
  title: string
}

export default function TrustedBy({ title }: TrustedByProps) {
  return (
    <Wrapper>
      <TitleWrapper>{title}</TitleWrapper>
      <ContentWrapper>
        <ShadowWrapper className="box1"></ShadowWrapper>
        <ShadowWrapper className="box2"></ShadowWrapper>
        <IconsWrapper>
          {CLIENTS_DATA.map(({ image }, index) => (
            <IconWrapper key={index}>
              <NextImg src={image} width={150} height={50} />
            </IconWrapper>
          ))}
          {CLIENTS_DATA.map(({ image }, index) => (
            <IconWrapper key={index + CLIENTS_DATA.length}>
              <NextImg src={image} width={150} height={50} />
            </IconWrapper>
          ))}
        </IconsWrapper>
      </ContentWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
  padding-top: 3vh;
  padding-bottom: 3vh;

  @keyframes move {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-50%);
    }
  }
  @media (${theme.xl}) {
    padding: 30px 0;
  }
`

const ContentWrapper = styled.div`
  overflow: hidden;
  position: relative;
  display: flex;
  width: 100%;
  max-width: 100vw;
  margin: 20px auto 0;
  height: auto;
`

const ShadowWrapper = styled.div`
  width: 200px;
  height: 100%;
  position: absolute;
  left: -1px;
  z-index: 333;
  background: linear-gradient(
    to right,
    ${theme.color.dark},
    ${theme.color.dark}00
  );
  &.box2 {
    left: auto;
    right: -1px;
    transform: rotate(180deg);
  }
  @media (${theme.xl}) {
    width: 20%;
  }
  overflow: hidden;
`

const TitleWrapper = styled.p`
  color: #fff;
  text-align: center;
  margin-bottom: 10px;
  text-transform: uppercase;
`

const IconsWrapper = styled.div`
  display: flex;
  justify-content: start;
  padding: 0 20px;
  gap: 32px;
  animation: move 60s linear infinite;

  @media (${theme.xl}) {
    gap: 6px;
  }
`

const IconWrapper = styled.div`
  width: 150px;
  flex-shrink: 0;
  @media (${theme.xl}) {
    width: 120px;
  }
`
