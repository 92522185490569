import React from "react"
import styled, { DefaultTheme, useTheme } from "styled-components"
import dynamic from "next/dynamic"
import { theme } from "@/common/Mixin"
import NextImg from "../NextImg"
import Button from "../_UI/ButtonNew"
import { useMediaQuery } from "@mui/material"

const Lottie = dynamic(() => import("lottie-react"))
const Fade = require("react-reveal/Fade")

type PaletteType = "light" | "lightLinear" | "darkLinear" | "dark"

interface Palette {
  background: string
  title: string
  slogan: string
  desc: string
  buttonStyleType: "secondary" | "subtle"
}

const getPalette = (type: PaletteType, theme: DefaultTheme): Palette => {
  switch (type) {
    case "light":
      return {
        background: theme.colors.backgroundFamily.light,
        title: theme.colors.textFamily.black,
        slogan: theme.colors.textFamily.black,
        desc: theme.colors.textFamily.gray,
        buttonStyleType: "secondary"
      }
    case "lightLinear":
      return {
        background: theme.colors.linearFamily.darkLight,
        title: theme.colors.textFamily.black,
        slogan: theme.colors.textFamily.black,
        desc: theme.colors.textFamily.gray,
        buttonStyleType: "secondary"
      }
    case "dark":
      return {
        background: theme.colors.linearFamily.back,
        title: theme.colors.textFamily.contrast,
        slogan: theme.colors.primary,
        desc: theme.colors.textFamily.disabled,
        buttonStyleType: "subtle"
      }
    case "darkLinear":
      return {
        background: theme.colors.linearFamily.light,
        title: theme.colors.textFamily.contrast,
        slogan: theme.colors.textFamily.contrast,
        desc: theme.colors.textFamily.disabled,
        buttonStyleType: "subtle"
      }
  }
}

export interface CardData {
  wide?: boolean
  backgroundType?: PaletteType
  icon?: any
  title?: string
  slogan?: string
  description?: string
  button?: {
    text: string
    href: string
    disabled?: boolean
  }
  additionalElements?: React.ReactNode[]
  image?: any
  imageType?: "lottie" | "img"
  customerImageWrapperStyle?: object
}

export interface CardProps extends CardData {
  cardName?: any
}

export default function Card({
  cardName,
  wide = false,
  backgroundType = "light",
  icon,
  title,
  slogan,
  description,
  button,
  additionalElements,
  image,
  customerImageWrapperStyle,
  imageType = "img"
}: CardProps) {
  const theme = useTheme()
  const isMobile = useMediaQuery(`(${theme.lg})`)
  if (isMobile) {
    wide = false
  }
  const palette = getPalette(backgroundType, theme)

  const renderImage = () => {
    return (
      <ImageWrapper wide={wide} style={customerImageWrapperStyle}>
        {imageType === "img" ? (
          <NextImg src={image.src} alt="Bottom Image" />
        ) : (
          <Lottie animationData={image} />
        )}
      </ImageWrapper>
    )
  }

  return (
    <CardWrapper
      key={cardName}
      wide={wide}
      style={{ background: palette.background }}
    >
      <TopWrapper wide={wide}>
        <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
          {icon && (
            <Fade bottom>
              <IconWrapper>
                <NextImg
                  style={{ width: "44px" }}
                  src={icon.src}
                  alt="Product Icon"
                />
              </IconWrapper>
            </Fade>
          )}
          {title && (
            <Fade bottom>
              <TitleWrapper style={{ color: palette.title }} className="body">
                {title}
              </TitleWrapper>
            </Fade>
          )}
        </div>
        {slogan && (
          <Fade bottom>
            <SloganWrapper style={{ color: palette.slogan }} className="title">
              {slogan}
            </SloganWrapper>
          </Fade>
        )}
        {description && (
          <Fade bottom>
            <DescWrapper style={{ color: palette.desc }} className="body">
              {description}
            </DescWrapper>
          </Fade>
        )}
        {button && (
          <Fade bottom>
            <Button
              disabled={button?.disabled}
              content={button?.text}
              href={button?.href}
              styleType={palette.buttonStyleType}
            />
          </Fade>
        )}
        {additionalElements && (
          <div
            style={{
              marginTop: "3vh",
              display: "flex",
              gap: "14px",
              alignItems: "center",
              flexWrap: "wrap"
            }}
          >
            {additionalElements.map(e => e)}
          </div>
        )}
      </TopWrapper>
      {image && renderImage()}
    </CardWrapper>
  )
}

export const CardWrapper = styled.div<{ wide: boolean }>`
  height: 100%;
  width: 100%;
  position: relative;

  display: flex;
  padding: 34px 0px 0px 0px;
  flex-direction: ${({ wide }) => (wide ? "row" : "column")}};
  justify-content: space-between;
  gap: 12px;
  align-items: center;
  flex: 1 0 0;

  border-radius: 16px;

  @media (${theme.lg}) {
    padding: 16px 20px 0px 20px;
    max-width: 100%;
    overflow: unset;
  }
`

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const TitleWrapper = styled.div``

const SloganWrapper = styled.div``

const DescWrapper = styled.div``

const TopWrapper = styled.div<{ wide: boolean }>`
  width: 100%;
  max-width: 35vw;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;

  ${({ wide }) =>
    wide &&
    `
    padding-left: 3vw;
  `}

  @media (${theme.lg}) {
    padding: 16px 20px 0px 20px;
    max-width: unset;
  }
`

const ImageWrapper = styled.div<{ wide: boolean }>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ wide }) =>
    wide &&
    `
    height: 100%;
  `}
`
