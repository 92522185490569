import React from "react"
import styled from "styled-components"
import { theme } from "@/common/Mixin"
import { SolutionNames } from "@/common/data/Products"
import SolutionCard from "./SolutionCard"

export interface SolutionCardListProps {
  cards: SolutionNames[]
}

export default function SolutionCardList({ cards }: SolutionCardListProps) {
  return (
    <LayoutWrapper className="kg-wrapper">
      {cards.map((card, index) => (
        <SolutionCard key={card} cardName={card} type={card} />
      ))}
    </LayoutWrapper>
  )
}

const LayoutWrapper = styled.section`
  display: flex;
  align-items: flex-start;
  gap: 42px;
  align-self: stretch;

  @media (${theme.lg}) {
    flex-direction: column;
  }
`
