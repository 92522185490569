import React from "react"

import { useTranslation } from "next-i18next"
import { ShadowWrapper as BGShadowWrapper } from "@/common/components/ProductBanner/BannerImages"

import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination, EffectFade, Autoplay } from "swiper/modules"

import { ProductBanner } from "@/common/components/ProductBanner"
import NextImg from "@/common/components/NextImg"
import styled, { useTheme } from "styled-components"
import { theme } from "@/common/Mixin"
import { useMediaQuery } from "@mui/material"
import { useEffect, useLayoutEffect, useState } from "react"

export default function HomeProductBanner() {
  const theme = useTheme()
  const { t } = useTranslation(["homepage"])
  const isPhone = useMediaQuery(`(${theme.sm})`)
  const isPad = useMediaQuery(`(${theme.lg})`)
  const [items, setItems] = useState<React.ReactNode[]>([])

  const useIsomorphicLayoutEffect =
    typeof window !== "undefined" ? useLayoutEffect : useEffect
  useIsomorphicLayoutEffect(() => {
    setItems(
      [0, 1, 2].map(idx => {
        let image
        if (isPhone) {
          image = require(
            `@/common/assets/image/homepage/Banner${idx}iPhone.avif`
          )
        } else if (isPad) {
          image = require(
            `@/common/assets/image/homepage/Banner${idx}iPad.webp`
          )
        } else {
          image = require(`@/common/assets/image/homepage/Banner${idx}.webp`)
        }

        let href
        if (idx === 0) {
          href = undefined
        } else if (idx === 1) {
          href = "/products/wallet"
        } else if (idx === 2) {
          href =
            "/blog/kryptogo-launches-ai-powered-one-stop-web3-cloud-platform"
        }

        let onClick
        if (idx === 0) {
          onClick = (e: any) => {
            e.preventDefault()
            document.getElementById("home-studio")?.scrollIntoView({
              behavior: "smooth",
              block: "center"
            })
          }
        } else {
          onClick = undefined
        }

        let target
        if (idx === 1) {
          target = "_self"
        } else {
          target = "_blank"
        }

        return (
          <React.Suspense
            key={`Banner_${idx}`}
            fallback={<div>Loading...</div>}
          >
            <ProductBanner
              style={{ marginBottom: "36px", overflow: "hidden" }}
              bannerType="home"
              title={t(`banner.title0.${idx}`)}
              titleType="primary"
              titleh1={t(`banner.title1.${idx}`)}
              description={t(`banner.desc.${idx}`)}
              primaryButton={{
                content: t(`banner.button.${idx}`),
                href,
                target,
                onClick
              }}
              backgroundImage={
                <>
                  <BGShadowWrapper />
                  <NextImg src={image} alt="home-banner-bg" priority />
                </>
              }
            />
          </React.Suspense>
        )
      })
    )
  }, [t, isPhone, isPad])

  return (
    <SwiperWrapper>
      <Swiper
        pagination={{
          clickable: true
        }}
        modules={[Pagination, EffectFade, Autoplay]}
        className="mySwiper kg-text-shadow"
        effect={"fade"}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false
        }}
        slidesPerView={1}
      >
        {items.map((item, index) => (
          <SwiperSlide
            style={{
              position: "relative"
            }}
            key={`Banner_${index}`}
          >
            {item}
          </SwiperSlide>
        ))}
      </Swiper>
    </SwiperWrapper>
  )
}

const SwiperWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 80vmin;
  .swiper-pagination-bullet {
    transition: 0.3s;
    background: rgba(255, 255, 255, 0.8);
    opacity: 1;
    margin: 0;
    height: 6px;
    width: 16px;
    border-radius: 10px;
  }
  .swiper-pagination-bullet-active {
    background: ${theme.color.primary};
    width: 80px;
    border-radius: 10px;
  }
`
